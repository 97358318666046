const state = {
  ruleForms: {
    /** 用户名 */
    username: '',
    /** 密码 */
    pass: ''
  },
  checked: false
}

const getters = {
  ruleForms: (state) => state.ruleForms,
  checked: (state) => state.checked
}

const actions = {
  /** 记住密码 */
  addPassword({ commit }, value) {
    commit('add_password', value)
  },

  /** 记住密码 */
  setChecked({ commit }, value) {
    commit('set_checked', value)
  }
}

const mutations = {
  add_password(state, value) {
    state.ruleForms = value
  },

  set_checked(state, value) {
    state.checked = value
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
