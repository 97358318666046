<template>
  <div class="forms">
    <div :style="titleStyle" style="color: #0062e2">
      {{ title }}
    </div>
    <el-form
      :style="loginFormStyle"
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="username" :style="formItemStyle">
        <el-input
          :style="inputStyle"
          prefix-icon="el-icon-user"
          v-model="ruleForm.username"
          :placeholder="form.username.rule1"
        ></el-input>
      </el-form-item>
      <el-form-item prop="pass" :style="formItemStyle">
        <el-input
          :style="inputStyle"
          type="password"
          prefix-icon="el-icon-lock"
          :placeholder="form.pass.rule1"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <div class="update">
        <el-checkbox v-model="isChecked" @change="change">记住密码</el-checkbox>
        <span @click="updatePass" class="update-password">修改密码</span>
      </div>

      <el-form-item :style="formItemStyle">
        <el-button
          :style="buttonStyle"
          type="primary"
          round
          @click="submitForm('ruleForm')"
          style="width: 100%; height: 100%"
        >
          登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Form, FormItem, Button, Input, Checkbox } from 'element-ui'
import { TITLE, FORM } from '../config'
import { AfterLogin } from '../utils'
import { Login } from '../api'
import md5 from 'md5'
import { mapActions, mapGetters } from 'vuex'
import { Base64 } from 'js-base64'

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox
  },

  computed: {
    ...mapGetters([
      'titleStyle',
      'formItemStyle',
      'inputStyle',
      'buttonStyle',
      'loginFormStyle',
      'ruleForms',
      'checked'
    ])
  },

  watch: {
    checked() {
      this.isChecked = this.checked
    }
  },

  data() {
    /** 校验用户名 */
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.form.username.rule1))
      }

      if (value.toString().length < this.form.username.min) {
        return callback(new Error(this.form.username.rule2))
      }

      if (value.toString().length > this.form.username.max) {
        return callback(new Error(this.form.username.rule3))
      }

      callback()
    }

    /** 校验密码 */
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.form.pass.rule1))
      }

      if (value.toString().length < this.form.pass.min) {
        return callback(new Error(this.form.pass.rule2))
      }

      if (value.toString().length > this.form.pass.max) {
        return callback(new Error(this.form.pass.rule3))
      }

      callback()
    }

    return {
      title: TITLE,
      form: FORM,
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        username: [{ validator: checkUsername, trigger: 'blur' }]
      },
      ruleForm: {
        /** 用户名 */
        username: '',
        /** 密码 */
        pass: ''
      },
      isChecked: false
    }
  },

  mounted() {
    this.isChecked = this.checked
    this.ruleForm.username = this.ruleForms.username
    this.ruleForm.pass = this.ruleForms.pass
  },

  methods: {
    ...mapActions(['addPassword', 'setChecked']),

    /** 提交 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login()
        }
      })
    },

    /** 记住密码 */
    change(value) {
      this.setChecked(value)
    },

    /** 登录 */
    async login() {
      let password = this.ruleForm.pass

      if (
        this.ruleForm.username === this.ruleForms.username &&
        this.ruleForm.pass === this.ruleForms.pass
      ) {
        password = Base64.decode(this.ruleForms.pass)
      }

      const params = {
        userName: this.ruleForm.username,
        password: md5(password)
      }

      const { code, message, data } = await Login(params)

      if (code !== 0) {
        this.$message.error(message)
      } else {
        if (this.checked) {
          this.addPassword({
            username: this.ruleForm.username,
            pass: Base64.encode(password)
          })
        } else {
          this.addPassword({ username: '', pass: '' })
        }

        AfterLogin(data)
      }
    },

    /** 修改密码 */
    updatePass() {
      this.$router.push('/update')
    }
  }
}
</script>

<style scoped lang="less">
.update {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  font-size: 14px;
}

.update-password {
  cursor: pointer;
  color: #989898;
}

.forms {
  width: 90%;
  height: 90%;
  padding: 5% 5%;
  background: #fff;
}

/deep/ .el-form-item__content {
  height: 100%;
}

/deep/ .el-input__inner {
  height: 100%;
}
</style>
