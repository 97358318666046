export const TITLE = '必发体育专属登录'

export const FORM = {
  username: {
    min: 2,
    max: 16,
    rule1: '请输入用户名',
    rule2: '用户名最小长度为2位',
    rule3: '用户名超出长度'
  },
  pass: {
    min: 8,
    max: 16,
    rule1: '请输入密码',
    rule2: '密码最小长度为8位',
    rule3: '密码长度不正确，应为8-16个字符'
  },
  newPass: {
    min: 8,
    max: 16,
    rule1: '请输入新密码',
    rule2: '密码最小长度为8位',
    rule3: '密码长度不正确，应为8-16个字符',
    rule4: '密码在8-16个字符（字母、数字）组成，区分大小写'
  }
}
