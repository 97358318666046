const state = {
  /** 是否pc */
  isPc: true,
  /** 标题样式 */
  titleStyle: {},
  /** 表单行样式 */
  formItemStyle: {},
  /** 输入框样式 */
  inputStyle: {},
  /** 登录界面样式 */
  loginStyle: {},
  /** 按钮样式 */
  buttonStyle: {},
  /** 返回样式 */
  backStyle: {},
  /** 登录表单 */
  loginFormStyle: {},
  /** 修改密码表单 */
  updateFormStyle: {}
}

const getters = {
  isPc: (state) => state.isPc,
  titleStyle: (state) => state.titleStyle,
  formItemStyle: (state) => state.formItemStyle,
  inputStyle: (state) => state.inputStyle,
  loginStyle: (state) => state.loginStyle,
  buttonStyle: (state) => state.buttonStyle,
  backStyle: (state) => state.backStyle,
  loginFormStyle: (state) => state.loginFormStyle,
  updateFormStyle: (state) => state.updateFormStyle
}

const actions = {
  setIsPc({ commit }, value) {
    commit('set_isPc', value)
  }
}

const mutations = {
  set_isPc(state, value) {
    state.isPc = value

    if (value) {
      state.titleStyle = {
        fontSize: '42px',
        marginTop: '26px',
        marginBottom: '30px',
        fontWeight: 'bold'
      }
      state.formItemStyle = { height: '12%', marginBottom: '36px' }
      state.inputStyle = { height: '100%' }
      state.loginStyle = {
        backgroundImage: `url(${require('../../assets/pcbg.jpg')})`,
        minWidth: '1200px',
        minHeight: '700px'
      }
      state.buttonStyle = { borderRadius: '50px' }
      state.backStyle = {
        padding: '20px',
        fontSize: '40px',
        fontWeight: 'bold'
      }
      state.loginFormStyle = {
        height: '100%'
      }
      state.updateFormStyle = {
        height: '100%'
      }
    } else {
      state.titleStyle = {
        fontSize: '24px',
        marginTop: '20px',
        marginBottom: '24px',
        fontWeight: 'bold'
      }
      state.formItemStyle = {}
      state.inputStyle = { height: '40px' }
      state.loginStyle = {
        backgroundImage: `url(${require('../../assets/mbbg.jpg')})`
      }
      state.buttonStyle = { borderRadius: '50px' }
      state.backStyle = {
        padding: '10px',
        fontSize: '20px',
        fontWeight: 'bold'
      }
      state.loginFormStyle = {
        height: '80%'
      }
      state.updateFormStyle = {
        height: '80%'
      }
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
