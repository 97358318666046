<template>
  <div class="login" :style="loginStyle">
    <div v-show="!isPc" class="mobile-form">
      <div class="logo">
        <img class="logo-img" src="../assets/logos.png" alt="" />
      </div>
      <div class="form-div">
        <div class="form">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div class="pc-form" v-show="isPc">
      <div class="logo">
        <img class="logo-img" src="../assets/logos.png" alt="" />
      </div>
      <div class="form-div">
        <div class="form">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IsMobile, Debounce } from '../utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isPc', 'loginStyle'])
  },

  mounted() {
    this.resize()
    IsMobile() ? this.setIsPc(false) : this.setIsPc(true)
  },

  methods: {
    ...mapActions(['setIsPc']),

    /** 窗口尺寸变化 */
    resize() {
      window.onresize = Debounce(() => {
        IsMobile() ? this.setIsPc(false) : this.setIsPc(true)
      }, 100)
    }
  }
}
</script>

<style scoped lang="less">
.login {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.pc-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .logo {
    width: 20%;
    padding-top: 5%;
  }

  .logo-img {
    width: 100px;
    height: 100px;
  }

  .form-div {
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form {
    width: 60%;
    height: 58%;
    min-height: 400px;
    min-width: 400px;
  }
}

.mobile-form {
  width: 100%;
  height: 100%;

  .logo {
    height: 33%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .logo-img {
    width: 80px;
    height: 80px;
  }

  .form-div {
    margin-top: 10%;
    min-height: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .form {
    width: 80%;
  }
}
</style>
