/** 是否移动端 */
export const IsMobile = () => {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

/** 登录之后 */
export const AfterLogin = ({ accessCode }) => {
  let path

  if (IsMobile()) {
    path = process.env.VUE_APP_MOBILEURL
  } else {
    path = process.env.VUE_APP_PCURL
  }

  window.location.href = `${path}?code=${accessCode}&type=app&rootUrl=${window.location.href}`
}

/** 防抖 */
export function Debounce(fn, delay) {
  let timer = null //借助闭包
  return function() {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, delay) // 简化写法
  }
}
