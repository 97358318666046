import axios from '../axios'

/** 登录 */
export const Login = (params) => {
  return axios.post('/user/directUserLogIn', params)
}

/** 修改密码 */
export const UpdatePass = (params) => {
  return axios.post('/user/updateDirectUserPassword', params)
}
