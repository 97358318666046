import Vue from 'vue'
import App from './App.vue'
import router from './route'
import { Message } from 'element-ui'
import store from '@/store'

Vue.config.productionTip = false
Vue.prototype.$message = Message

const app = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

export default app
