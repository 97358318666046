import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Login from './modules/login'
import User from './modules/user'

Vue.use(Vuex)

/** 存储到 sessionStorage 模块 */
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['Login']
})

/** 存储到 localStorage 模块 */
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['User']
})

export default new Vuex.Store({
  modules: {
    Login,
    User
  },
  plugins: [vuexSession.plugin, vuexLocal.plugin],
  strict: process.env.NODE_ENV !== 'production' // 严格模式
})
