import vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
import LoginForm from '../components/LoginForm.vue'

vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/',
      component: Login,
      children: [
        {
          path: '',
          component: LoginForm
        },
        {
          path: '/update',
          component: () => import('../components/UpdatePasswordForm.vue')
        }
      ]
    }
  ]
})
